import { pathToRegExp } from "./urlPath"

export default function getCurrentStep(url?: string): Array<number> {
  const defaultStep = 1
  const defaultSubStep = 1

  if (url === undefined) {
    return [defaultStep, defaultSubStep]
  }

  const groups = pathToRegExp(url)?.groups

  if (groups === undefined) {
    return [defaultStep, defaultSubStep]
  }

  return [Number(groups.step), Number(groups.substep)]
}
