export const TENANT_DOMAIN_ENVIRONMENT_MAPPING = {
  unicreditStaging: "valuation-flow-ucsc",
  unicreditPreview: "valuation-flow-ucsc-preview",
  unicreditProduction: "unicreditres",
}
export const TENANTS = {
  ucsc: "UCSC",
  casavo: "CASAVO",
} as const

export const UNICREDIT_DOMAIN = "https://www.unicreditres.it"
