import {
  limitTotalCountTo,
  takeAtLeastNPotentialBuyer,
  takeFirstNPotentialBuyers,
} from "@app/lib/services/PotentialBuyers/filters"
import { IPotentialBuyerMatchingService } from "@app/lib/services/PotentialBuyers/IPotentialBuyerMatchingService"
import { retry } from "@app/lib/services/retry"
import { PotentialBuyers } from "@app/types/PotentialBuyers"
import { SellerPropertyValuationId } from "@app/types/sellerPropertyValuation"
import { pipe } from "fp-ts/lib/function"

export class PotentialBuyerMatchingService implements IPotentialBuyerMatchingService {
  private readonly baseService: IPotentialBuyerMatchingService
  private readonly maxRetry: number
  private readonly retryDelayMs: number

  constructor(
    service: IPotentialBuyerMatchingService,
    config: { maxRetry: number; retryDelayMS: number } = { maxRetry: 3, retryDelayMS: 300 }
  ) {
    this.baseService = service
    this.maxRetry = config.maxRetry
    this.retryDelayMs = config.retryDelayMS
  }

  async findPotentialBuyerFor(valuation: SellerPropertyValuationId): Promise<PotentialBuyers | undefined> {
    const loadPotentialBuyer = retry(
      (valuation: SellerPropertyValuationId) => this.baseService.findPotentialBuyerFor(valuation),
      async (data) => {
        return (await data) !== undefined
      },
      this.maxRetry,
      this.retryDelayMs,
      undefined
    )
    const loadedData = await loadPotentialBuyer(valuation)
    if (loadedData === undefined) return undefined
    return PotentialBuyerMatchingService.filterBuyers(loadedData)
  }

  static filterBuyers<T extends PotentialBuyers>(buyers: T): T | undefined {
    return pipe(buyers, limitTotalCountTo<T>(99), takeFirstNPotentialBuyers<T>(3), takeAtLeastNPotentialBuyer<T>(3))
  }
}
