import { BusinessLineRejectionReasonsDTO, BusinessLinesDTO } from "@app/lib/services/SnakeService/types"
import { BusinessLineReason, BusinessLinesEligibility } from "@app/types/sellerPropertyValuation"
import { getLoggerServiceSingleton } from "../../ioc/loggerServiceSingleton"

export type RenovationStatus =
  | "not_renovated"
  | "habitable"
  | "partially_renovated"
  | "renovated"
  | "fully_renovated"
  | "new"

function mapBusinessLinesReason(reason: BusinessLineRejectionReasonsDTO[number]): BusinessLineReason | null {
  switch (reason) {
    case "city_not_served":
    case "doesnt_want_to_sell":
    case "zip_code_not_served":
      return reason
    case "not_matching_business_line_rules":
    case "property_not_matching_guaranteed_sale_rules":
    case "property_not_matching_ibuyer_rules":
      return "not_matching_business_line_rules"
    case "eva_valuation_too_high":
      getLoggerServiceSingleton().error(`Unsupported business rejection reason eva_valuation_too_high`)
      return null
  }
}

const mapElibigilityToInternal = (
  line: BusinessLinesDTO[keyof BusinessLinesDTO],
  sellerPropertyValuationId: string | undefined
) => {
  if (line.eligible === undefined) {
    getLoggerServiceSingleton().error(
      `Business line eligible undefined for seller property valuation ${sellerPropertyValuationId}`
    )
  }

  const reasons = line.reasons?.map(mapBusinessLinesReason).filter(Boolean) as BusinessLineReason[] | undefined

  return {
    eligible: line.eligible || false,
    ...(!line.eligible ? { reasons } : {}),
    ...(!line.eligible ? { potentiallyEligible: line.potentiallyEligible } : {}),
  }
}

export const mapBusinessLinesV2Eligibility = (
  businessLines: BusinessLinesDTO,
  sellerPropertyValuationId?: string
): BusinessLinesEligibility => {
  const { agency, leadRouting } = businessLines

  return {
    agency: mapElibigilityToInternal(agency, sellerPropertyValuationId),
    leadRouting: mapElibigilityToInternal(leadRouting, sellerPropertyValuationId),
  }
}
