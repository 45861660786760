import { IPotentialBuyerMatchingService } from "@app/lib/services/PotentialBuyers/IPotentialBuyerMatchingService"
import { PotentialBuyers } from "@app/types/PotentialBuyers"
import { SellerPropertyValuationId } from "@app/types/sellerPropertyValuation"
import { AxiosInstance } from "axios"

export class SnakePotentialBuyerMatchingService implements IPotentialBuyerMatchingService {
  private httpClient: AxiosInstance

  constructor(axios: AxiosInstance) {
    this.httpClient = axios
  }

  async findPotentialBuyerFor(valuationId: SellerPropertyValuationId): Promise<PotentialBuyers | undefined> {
    const { status, data } = await this.httpClient.get(
      `${process.env.NEXT_PUBLIC_SNAKE_API_BASE}/v1/seller-property-valuations/${valuationId}/potential-buyers`
    )
    if (status == 202) {
      //Accepted -> snake doesn't have the data yet
      return undefined
    }
    return data
  }
}
