export const propriooZipCodes = [
  "01600",
  "01390",
  "01700",
  "06000",
  "06100",
  "06110",
  "06130",
  "06140",
  "06150",
  "06160",
  "06190",
  "06200",
  "06210",
  "06220",
  "06230",
  "06240",
  "06250",
  "06260",
  "06270",
  "06300",
  "06310",
  "06320",
  "06330",
  "06340",
  "06360",
  "06370",
  "06380",
  "06390",
  "06400",
  "06410",
  "06420",
  "06430",
  "06440",
  "06450",
  "06460",
  "06470",
  "06480",
  "06500",
  "06510",
  "06520",
  "06530",
  "06540",
  "06550",
  "06560",
  "06570",
  "06580",
  "06590",
  "06600",
  "06610",
  "06620",
  "06640",
  "06650",
  "06660",
  "06670",
  "06690",
  "06700",
  "06710",
  "06730",
  "06740",
  "06750",
  "06790",
  "06800",
  "06810",
  "06830",
  "06850",
  "06910",
  "06950",
  "13001",
  "13002",
  "13003",
  "13004",
  "13005",
  "13006",
  "13007",
  "13008",
  "13009",
  "13010",
  "13011",
  "13012",
  "13013",
  "13080",
  "13090",
  "13100",
  "13105",
  "13109",
  "13111",
  "13112",
  "13114",
  "13119",
  "13120",
  "13122",
  "13124",
  "13126",
  "13290",
  "13320",
  "13360",
  "13390",
  "13410",
  "13420",
  "13480",
  "13510",
  "13530",
  "13540",
  "13590",
  "13610",
  "13620",
  "13650",
  "13710",
  "13720",
  "13740",
  "13760",
  "13770",
  "13790",
  "13820",
  "13840",
  "13850",
  "13880",
  "13950",
  "13960",
  "38080",
  "38230",
  "38280",
  "38290",
  "38440",
  "38460",
  "38510",
  "38550",
  "38670",
  "44000",
  "44100",
  "44110",
  "44115",
  "44116",
  "44117",
  "44118",
  "44119",
  "44120",
  "44130",
  "44140",
  "44150",
  "44160",
  "44170",
  "44190",
  "44200",
  "44210",
  "44220",
  "44230",
  "44240",
  "44250",
  "44260",
  "44270",
  "44290",
  "44300",
  "44310",
  "44320",
  "44330",
  "44340",
  "44350",
  "44360",
  "44370",
  "44380",
  "44390",
  "44400",
  "44410",
  "44420",
  "44430",
  "44440",
  "44450",
  "44460",
  "44470",
  "44480",
  "44490",
  "44500",
  "44510",
  "44520",
  "44521",
  "44522",
  "44530",
  "44540",
  "44550",
  "44560",
  "44570",
  "44580",
  "44590",
  "44600",
  "44610",
  "44620",
  "44630",
  "44640",
  "44650",
  "44660",
  "44670",
  "44680",
  "44690",
  "44700",
  "44710",
  "44720",
  "44730",
  "44740",
  "44750",
  "44760",
  "44770",
  "44780",
  "44800",
  "44810",
  "44830",
  "44840",
  "44850",
  "44860",
  "44880",
  "44980",
  "69001",
  "69002",
  "69003",
  "69004",
  "69005",
  "69006",
  "69007",
  "69008",
  "69009",
  "69100",
  "69110",
  "69115",
  "69124",
  "69126",
  "69130",
  "69140",
  "69150",
  "69160",
  "69190",
  "69200",
  "69210",
  "69220",
  "69230",
  "69250",
  "69260",
  "69270",
  "69280",
  "69290",
  "69300",
  "69310",
  "69320",
  "69330",
  "69340",
  "69350",
  "69360",
  "69370",
  "69380",
  "69390",
  "69400",
  "69410",
  "69420",
  "69430",
  "69440",
  "69450",
  "69460",
  "69480",
  "69500",
  "69510",
  "69520",
  "69530",
  "69540",
  "69570",
  "69580",
  "69600",
  "69630",
  "69650",
  "69660",
  "69670",
  "69680",
  "69690",
  "69700",
  "69720",
  "69730",
  "69740",
  "69760",
  "69780",
  "69800",
  "69820",
  "69890",
  "69910",
  "69960",
  "69970",
  "75001",
  "75002",
  "75003",
  "75004",
  "75005",
  "75006",
  "75007",
  "75008",
  "75009",
  "75010",
  "75011",
  "75012",
  "75013",
  "75014",
  "75015",
  "75016",
  "75017",
  "75018",
  "75019",
  "75020",
  "78000",
  "78100",
  "78110",
  "78140",
  "78150",
  "78170",
  "78180",
  "78200",
  "78210",
  "78220",
  "78230",
  "78280",
  "78310",
  "78340",
  "78350",
  "78360",
  "78380",
  "78400",
  "78420",
  "78430",
  "78440",
  "78500",
  "78530",
  "78600",
  "78800",
  "78960",
  "78990",
  "83119",
  "83470",
  "83910",
  "92000",
  "92100",
  "92110",
  "92120",
  "92130",
  "92140",
  "92150",
  "92160",
  "92170",
  "92190",
  "92200",
  "92210",
  "92220",
  "92230",
  "92240",
  "92250",
  "92260",
  "92270",
  "92290",
  "92300",
  "92310",
  "92320",
  "92330",
  "92340",
  "92350",
  "92360",
  "92370",
  "92380",
  "92390",
  "92400",
  "92410",
  "92420",
  "92430",
  "92500",
  "92600",
  "92700",
  "92800",
  "93100",
  "93170",
  "93260",
  "93300",
  "93400",
  "93500",
  "94000",
  "94100",
  "94110",
  "94120",
  "94130",
  "94140",
  "94160",
  "94170",
  "94190",
  "94200",
  "94210",
  "94220",
  "94230",
  "94240",
  "94250",
  "94260",
  "94270",
  "94290",
  "94300",
  "94310",
  "94320",
  "94340",
  "94350",
  "94360",
  "94370",
  "94380",
  "94400",
  "94410",
  "94420",
  "94430",
  "94440",
  "94460",
  "94480",
  "94490",
  "94500",
  "94510",
  "94520",
  "94600",
  "94700",
  "94800",
  "95000",
  "95100",
  "95110",
  "95120",
  "95130",
  "95140",
  "95150",
  "95160",
  "95170",
  "95180",
  "95190",
  "95200",
  "95210",
  "95220",
  "95230",
  "95240",
  "95250",
  "95260",
  "95270",
  "95280",
  "95290",
  "95300",
  "95310",
  "95320",
  "95330",
  "95340",
  "95350",
  "95360",
  "95370",
  "95380",
  "95390",
  "95400",
  "95410",
  "95420",
  "95430",
  "95440",
  "95450",
  "95460",
  "95470",
  "95480",
  "95490",
  "95500",
  "95510",
  "95520",
  "95530",
  "95540",
  "95550",
  "95560",
  "95570",
  "95580",
  "95590",
  "95600",
  "95610",
  "95620",
  "95630",
  "95640",
  "95650",
  "95660",
  "95670",
  "95680",
  "95690",
  "95700",
  "95710",
  "95720",
  "95740",
  "95750",
  "95760",
  "95770",
  "95780",
  "95800",
  "95810",
  "95820",
  "95830",
  "95840",
  "95850",
  "95870",
  "95880",
]
