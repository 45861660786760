const itemKey = "unicredit_policy_accepted"

export const storeUnicreditMarketingPolicy = (unicreditMarketingPolicy?: string | string[]) => {
  if (typeof window === "undefined" || !unicreditMarketingPolicy) {
    return
  }
  if (Array.isArray(unicreditMarketingPolicy)) {
    sessionStorage.setItem(itemKey, unicreditMarketingPolicy[0])
  } else {
    sessionStorage.setItem(itemKey, unicreditMarketingPolicy)
  }
}

export const getUnicreditMarketingPolicy = () => {
  if (typeof window === "undefined") {
    return ""
  }
  return sessionStorage.getItem(itemKey)
}
