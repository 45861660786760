import { BodyLarge, Heading3 } from "@app/base-ui/Typography"
import { trackUserInteraction } from "@app/lib/tracking"
import useTranslation from "next-translate/useTranslation"
import { useEffect } from "react"

export const AppCrashedScreen = () => {
  const { t } = useTranslation()
  useEffect(() => {
    // using the function imported directly because the error boundary is outside the dependency container
    trackUserInteraction("SellFlow", "AppCrash", "ErrorBoundary")
  }, [])
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 205,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img src="/flow/images/Clouds.svg" style={{ marginBottom: 24, height: 200 }} />
      <Heading3 style={{ marginBottom: 24 }}>{t("common:Error_Service_Not_Available_Title")}</Heading3>
      <BodyLarge>{t("common:Generic_Error_Description")}</BodyLarge>
    </div>
  )
}
