import Script from "next/script"

export const DataLayer: React.FC = () => (
  <Script
    id="data-layer-init"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
        window.dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'dataLayerInit',
          originalLocation: document.location.protocol + '//' +
                            document.location.hostname +
                            document.location.pathname +
                            document.location.search
          })
      `,
    }}
  />
)
