import { hasGivenMeasurementTrackingConsent } from "./consent"

type EventName = "ValuationStarted" | "ValuationFinished" | "MQL" | "SEL"

export function buildAppWebViewEvent(event: EventName) {
  return {
    type: "valuation_flow_user_event",
    event,
  }
}

export const trackAppWebViewEvent = (eventName: EventName) => {
  if (hasGivenMeasurementTrackingConsent()) {
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(buildAppWebViewEvent(eventName)))
  }
}
