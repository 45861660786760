import { TENANTS } from "@app/lib/tenants/constants"
import { getTenant } from "@app/lib/tenants/getTenant"
import { hasGivenMarketingTrackingPreference } from "@app/lib/tracking/consent"
import { tealiumRecordPageView } from "@app/lib/tracking/tealium"
import { getTrafficSource } from "@app/lib/trafficSourceStorage"
import { getUnicreditMarketingPolicy } from "@app/lib/unicreditMarketingPolicyStorage"
import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"

export function useTealiumPageViewTracking() {
  const pathname = usePathname()
  const trafficSourceParam = useSearchParams().get("traffic_source")
  useEffect(() => {
    const tenant = getTenant()
    if (tenant !== TENANTS.ucsc) return
    const trafficSource = getTrafficSource() ?? trafficSourceParam
    const cookiePolicyAccepted = getUnicreditMarketingPolicy() ?? (hasGivenMarketingTrackingPreference() ? "Y1" : "NH")
    tealiumRecordPageView(pathname, trafficSource, cookiePolicyAccepted)
  }, [pathname, trafficSourceParam])
}
