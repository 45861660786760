import { BodyMedium } from "@app/base-ui/Typography"
import { toast, ToastContainer, ToastContent, ToastOptions } from "react-toastify"

export const FlowToastContainer = () => (
  <ToastContainer hideProgressBar style={{ padding: 16, paddingTop: 32 }} position="top-center" />
)

export const showToast = (content: ToastContent, options?: ToastOptions) => {
  const wrappedContent = typeof content === "string" ? <BodyMedium>{content}</BodyMedium> : content
  toast(wrappedContent, options)
}
