import { useRouter } from "next/router"
import { useEffect } from "react"
import { getTrafficSource } from "./trafficSourceStorage"

const MARKETING_CAMPAIGN_HEADERS = "marketing_campaign_headers"
export const useSaveMarketingCampaignParams = () => {
  const router = useRouter()
  useEffect(() => {
    const headers: Record<string, string> = {}

    for (const queryParam in queryParamsToHeaderMap) {
      const queryParamValue = router.query[queryParam]
      if (queryParamValue !== undefined) {
        headers[queryParamsToHeaderMap[queryParam]] = queryParamValue as string
      }
    }

    window.sessionStorage.setItem(MARKETING_CAMPAIGN_HEADERS, JSON.stringify(headers))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const queryParamsToHeaderMap: Record<string, string> = {
  at_gd: "at-gd",
  at_geo: "at-geo",
  ob_click_id: "ob-click-id",
}

export const getMarketingCampaignHeaders = (): Record<string, string> => {
  const headers = window.sessionStorage.getItem(MARKETING_CAMPAIGN_HEADERS)
  return headers ? { ...JSON.parse(headers), ...{ ["campaign-source"]: getTrafficSource() } } : {}
}
