import { useContext } from "react"
import { DependenciesContainerContext } from "./context"
import { FlowDependencies, FlowDependencyKey } from "./flowDependencies"

export function useDependency<TKey extends FlowDependencyKey>(key: TKey): FlowDependencies[TKey] {
  const container = useContext(DependenciesContainerContext)

  if (!container) throw new Error(`useDependency must be used within a DependenciesContainerContext`)

  return container.get(key)
}
