const itemKey = "traffic_source"

export const storeTrafficSource = (trafficSource?: string | string[]) => {
  if (typeof window === "undefined" || !trafficSource) {
    return
  }
  if (Array.isArray(trafficSource)) {
    sessionStorage.setItem(itemKey, trafficSource[0])
  } else {
    sessionStorage.setItem(itemKey, trafficSource)
  }
}

export const getTrafficSource = () => {
  if (typeof window === "undefined") {
    return ""
  }
  return sessionStorage.getItem(itemKey)
}
