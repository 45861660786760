
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AppCrashedScreen } from "@app/components/AppCrashedScreen"
import { ThemeProvider } from "@app/components/Theme"
import GlobalStyles from "@app/components/Theme/GlobalStyles"
import { ThirdPartyScripts } from "@app/components/ThirdPartyScripts"
import { FlowToastContainer } from "@app/components/toast"
import Tracker from "@app/components/Tracker"
import { isDevOrStaging } from "@app/lib/envUtils"
import { container, DependenciesContainerContext } from "@app/lib/ioc/context"
import { queryClient } from "@app/lib/queryClient"
import { initContainer } from "@app/lib/services"
import { persistor, store } from "@app/store"
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react"
import { AppInitialProps, AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { useCallback } from "react"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import "@app/components/Recaptcha/hideBadge.css"
import { useTealiumPageViewTracking } from "@app/components/UCSC/useTealiumPageViewTracking"
import "@app/design-lib/styles/global.css"
import { NextPageContext } from "next"
import "rc-slider/assets/index.css"
import "react-toastify/dist/ReactToastify.css"

import { habitatThemeContainerId } from "@app/const"
import { HabitatTheme } from "@casavo/habitat"
import "@casavo/habitat/style.css"

initContainer(container)

if (isDevOrStaging() && typeof window !== "undefined") {
  // getDebugUrl devtool
  ;(window as any).getDebugUrl = () => {
    const url = new URL(location.href)
    url.searchParams.set("state", JSON.stringify(store.getState()))
    return url.href
  }
}

type Props = AppProps

const CustomApp = ({ Component, pageProps }: Props) => {
  const router = useRouter()
  useTealiumPageViewTracking()

  if (typeof window !== "undefined") {
    // Reset scroll on each step
    router.events.on("beforeHistoryChange", () => {
      window.scrollTo(0, 0)
    })
  }

  if (typeof window !== "undefined" && isDevOrStaging()) {
    // preload state from url for helping in debugging
    if (router.query.state) {
      store.dispatch({ type: "set_state", payload: JSON.parse(router.query.state as string) })
      //remove the query param from the url
      const url = new URL(location.href)
      url.searchParams.delete("state")
      router.replace(url.href)
    }
  }

  const logger = container.get("logger")
  const sendErrorToLogger = useCallback(
    (error: Error, _componentStack: string, _eventId: string) => {
      logger.error(error)
    },
    [logger]
  )

  return (
    <QueryClientProvider client={queryClient}>
      <SentryErrorBoundary fallback={<AppCrashedScreen />} onError={sendErrorToLogger}>
        <Provider store={store}>
          <Head>
            <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
          </Head>
          <main id={habitatThemeContainerId} css={{ height: "100%" }} className={HabitatTheme}>
            <ThemeProvider>
              <DependenciesContainerContext.Provider value={container}>
                <Tracker>
                  <GlobalStyles />
                  <ThirdPartyScripts />
                  <PersistGate loading={null} persistor={persistor}>
                    <FlowToastContainer />
                    <Component {...pageProps} />
                  </PersistGate>
                </Tracker>
              </DependenciesContainerContext.Provider>
            </ThemeProvider>
          </main>
        </Provider>
      </SentryErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

// https://nextjs.org/docs/messages/opt-out-auto-static-optimization
// We decided to opt out of some of the pages being SSG due to the architectural setup we chose for Unicredit UCSC deploy
// (we do not know at build time which is the host that will launch the requests so we can not know the tenant)
CustomApp.getInitialProps = async ({
  Component,
  ctx,
}: AppProps & { ctx: NextPageContext }): Promise<AppInitialProps> => {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return {
    pageProps,
  }
}

const __Page_Next_Translate__ = CustomApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  