import { AxiosInstance } from "axios"
import { isDevOrStaging } from "../envUtils"
import { IPotentialBuyerMatchingService } from "./PotentialBuyers/IPotentialBuyerMatchingService"
import { PotentialBuyerMatchingService } from "./PotentialBuyers/PotentialBuyerMatchingService"
import { SnakePotentialBuyerMatchingService } from "./PotentialBuyers/SnakePotentialBuyerMatchingService"

const MAX_STAGING_LIKE_RETRIES = 7
const MAX_PRODUCTION_RETRIES = 5

export const buildPotentialBuyerMatchingService = (axiosInstance: AxiosInstance): IPotentialBuyerMatchingService => {
  const fetchBuyersMaxRetries = isDevOrStaging() ? MAX_STAGING_LIKE_RETRIES : MAX_PRODUCTION_RETRIES

  return new PotentialBuyerMatchingService(new SnakePotentialBuyerMatchingService(axiosInstance), {
    maxRetry: fetchBuyersMaxRetries,
    retryDelayMS: 500,
  })
}
