import { ReCaptchaSnippet } from "@app/components/Recaptcha/ReCaptchaSnippet"
import { initSnowplowTracker } from "@app/lib/tracking/snowplow"
import useTranslation from "next-translate/useTranslation"
import Script from "next/script"
import { useEffect } from "react"
import AntiFlicker from "../Optimize/AntiFlicker"
import { DataLayer } from "../Tracker/DataLayerInit"
import { GoogleTagManagerScript } from "../Tracker/GoogleTagManager"

export const ThirdPartyScripts = () => {
  const { lang } = useTranslation()

  useEffect(() => {
    initSnowplowTracker()
  }, [])

  return (
    <>
      <Script
        src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=3.111.0&features=Intl"
        strategy="beforeInteractive"
      />

      <GoogleTagManagerScript lang={lang} />

      <DataLayer />

      <AntiFlicker lang={lang} />

      <Script
        strategy="beforeInteractive"
        id="google-api-script"
        key="googleapis"
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places&language=${lang}`}
      />
      <ReCaptchaSnippet />
    </>
  )
}
