import Script from "next/script"

export const ReCaptchaSnippet = () => {
  const key = process.env.NEXT_PUBLIC_RECAPTCHA_KEY
  return (
    <Script
      strategy={"afterInteractive"}
      id="recaptcha"
      src={"https://www.google.com/recaptcha/enterprise.js?render=" + key}
      type="text/javascript"
    />
  )
}
