import { ILogger, LogMessage } from "./types"

export class ConsoleLogger implements ILogger {
  error(error: Error) {
    console.error("[Logger]", error)
  }

  warning(message: LogMessage) {
    console.warn("[Logger]", message)
  }

  info(message: LogMessage) {
    console.log("[Logger]", message)
  }

  debug(message: LogMessage) {
    console.debug("[Logger]", message)
  }
}
