import { Contact } from "@app/types/contact"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type ContactState = Contact

const initialState: ContactState = {
  dataToBeSold: false,
  marketingAllowed: false,
  name: "",
  surname: "",
  phone: "",
  email: "",
}

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContact: (_state, action: PayloadAction<Contact>) => {
      return action.payload
    },
  },
})

export const { setContact } = contactSlice.actions

export const contactReducer = contactSlice.reducer

export const contactSelector = (state: RootState) => state.contact

export function isEmptyContact(contact: Contact): boolean {
  return [contact.name, contact.email, contact.phone, contact.surname].every((field) => field.trim() === "")
}
