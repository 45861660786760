import { useAddress } from "@app/contexts/address"
import { useDependency } from "@app/lib/ioc/useDependency"
import { getTenant } from "@app/lib/tenants/getTenant"
import { getCity } from "@app/lib/tracking"
import useCurrentStep from "./useCurrentStep"

type PushToDataLayer = (action: string, data?: { move: string } | { leadId: string } | {}) => void
const useTracking = (): {
  pushToDataLayer: PushToDataLayer
} => {
  const pushFlowStepToDataLayer = useDependency("pushToDataLayerTracker")
  const [currentStep] = useCurrentStep()
  const [address] = useAddress()

  const pushToDataLayer: PushToDataLayer = (action, data = {}) => {
    const tenant = getTenant()
    pushFlowStepToDataLayer(currentStep, action, {
      city: getCity(address, tenant),
      zip: address.zipCode,
      ...data,
    })
  }

  return {
    pushToDataLayer,
  }
}

export default useTracking
