import Trans from "next-translate/Trans"
import React, { ReactNode } from "react"

const defaultComponents = {
  b: <strong style={{ fontWeight: 600 }} />,
  i: <em />,
  br: <br />,
}

export type ComponentMap = Record<string, React.ReactNode>

export const TokenSupport: React.FC<{ components?: ComponentMap; children: ReactNode }> = React.memo(
  function TokenSupport({ children, components }) {
    return (
      <>
        {React.Children.map(children, function (child) {
          if (typeof child === "string") {
            return (
              <Trans
                i18nKey={child.replace(/\n/g, "<br/>")}
                components={{
                  ...defaultComponents,
                  ...components,
                }}
              ></Trans>
            )
          }
          return child
        })}
      </>
    )
  }
)
