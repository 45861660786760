import { PotentialBuyers } from "@app/types/PotentialBuyers"
import { SellerPropertyValuation } from "@app/types/sellerPropertyValuation"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface ValuationResultState {
  valuation?: SellerPropertyValuation
  potentialBuyers?: PotentialBuyers
}

const initialState: ValuationResultState = {
  valuation: undefined,
  potentialBuyers: undefined,
}

export const valuationSlice = createSlice({
  name: "valuationResult",
  initialState,
  reducers: {
    mergeValuationResult: (state, action: PayloadAction<Partial<ValuationResultState>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { mergeValuationResult } = valuationSlice.actions

export const valuationResultReducer = valuationSlice.reducer

export const valuationResultSelector = (state: RootState) => state.valuationResult
