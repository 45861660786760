import {
  EnergyRating,
  FloorShift,
  HeatingSystem as LeadHeatingSystem,
  ResidentialType as LeadResidentialType,
  StateAtDeed as LeadStateAtDeed,
  StateOfMaintenance as LeadStateOfMaintenance,
  TrafficSources,
  ViewDescription,
} from "@app/types/sellerPropertyValuation"
import {
  EnergyClass,
  Floor,
  HeatingSystem,
  Orientation,
  ResidentialType,
  StateAtDeed,
  StateOfMaintenance,
} from "@app/types/survey"

export function getEnergyRating(energyClass?: EnergyClass): EnergyRating {
  switch (energyClass) {
    case "A":
      return "A"
    case "A+":
      return "APLUS"
    case "B":
      return "B"
    case "C":
      return "C"
    case "D":
      return "D"
    case "E":
      return "E"
    case "F":
      return "F"
    case "G":
      return "G"
    default:
      return "NONE"
  }
}

export function getFloor(floor?: Floor): number {
  switch (floor) {
    case "Basement":
    case "Ground Floor":
    case "Mezzanine":
    case undefined:
      return 0
    default:
      return Number(floor)
  }
}

export function getFloorShift(floor?: Floor): FloorShift {
  switch (floor) {
    case "Basement":
      return "LOWERED"
    case "Mezzanine":
      return "RAISED"
    default:
      return "NONE"
  }
}

export function getHeatingSystem(heatingSystem?: HeatingSystem): LeadHeatingSystem {
  switch (heatingSystem) {
    case "2":
      return "AUTONOMOUS"
    case "3":
      return "SHARED"
    case "0":
    case "1":
    default:
      return "NONE"
  }
}

export function getResidentialType(residentialType?: ResidentialType): LeadResidentialType {
  switch (residentialType) {
    case "1":
    case "16":
      return "APARTMENT"
    case "2":
      return "VILLA"
    case "6":
      return "LOFT"
    case "8":
      return "MANSARD"
    case "11":
      return "HOUSE"
    case "13":
      return "ATTIC"
    case "15":
      return "TOWNHOUSE"
    case "17":
      return "DUPLEX"
    case "18":
      return "STUDIO"
    case "19":
      return "COTTAGE"
    default:
      return "APARTMENT"
  }
}

export function getStateAtDeed(stateAtDeed?: StateAtDeed): LeadStateAtDeed {
  switch (stateAtDeed) {
    case "Free":
    case "Libero":
    case "Libre":
      return "FREE"
    case "Inhabited by the owner":
    case "Abitato dal proprietario":
    case "Habitada por el propietario":
      return "OCCUPIED"
    default:
      return "RENTED"
  }
}

export function getStateOfMaintenance(stateOfMaintenance?: StateOfMaintenance): LeadStateOfMaintenance {
  switch (stateOfMaintenance) {
    case "1":
      return "NEW"
    case "2":
      return "RENOVATED"
    case "3":
      return "HABITABLE"
    case "4":
    default:
      return "NEED_RENOVATION"
  }
}

export function getViewDescription(view?: Orientation): ViewDescription {
  switch (view) {
    case "Interior":
      return "INTERNAL"
    case "Exterior":
    default:
      return "EXTERNAL"
  }
}

export function isVilla(residentialType?: ResidentialType): boolean {
  return residentialType === "2"
}

export const mapTrafficSource = (trafficSource: string | null): TrafficSources => {
  const lowerCaseTrafficSource = trafficSource?.toLowerCase()
  switch (lowerCaseTrafficSource) {
    case "app-uc":
      return "APP_UC"
    case "app-buddy":
      return "APP_BUDDY"
    case "website":
    default:
      return "WEBSITE"
  }
}
