import { Report } from "@app/types/report"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type ReportState = {
  data: Report | undefined
}

const initialState: ReportState = { data: undefined }

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReport: (state, action: PayloadAction<Report>) => {
      return {
        ...state,
        data: action.payload,
      }
    },
  },
})

export const { setReport } = reportSlice.actions

export const reportReducer = reportSlice.reducer

export const reportSelector = (state: RootState) => state.report.data
