import { sleep } from "@app/lib/services/sleep"

type Loader<Params extends any[], T> = (...args: Params) => Promise<T>

type StopCondition<T> = (data: Promise<T>) => Promise<boolean>

export function retry<Params extends any[], T, Failure>(
  loader: Loader<Params, T>,
  stopCondition: StopCondition<T>,
  nTry: number,
  delay: number,
  failureValue: Failure
): Loader<Params, T | Failure> {
  return async (...args: Params) => {
    if (nTry == 0) {
      return failureValue
    }
    const data = loader(...args)
    const needRetry = await stopCondition(data)
    if (needRetry) {
      return data
    } else {
      await sleep(delay)
      return retry(loader, stopCondition, nTry - 1, delay, failureValue)(...args)
    }
  }
}
