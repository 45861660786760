import { validateZipCode } from "@app/lib/addressValidator"
import { Address } from "@app/types/address"
import { v4 as uuidv4 } from "uuid"
import { isDevOrStaging } from "../envUtils"
import { getLoggerServiceSingleton } from "../ioc/loggerServiceSingleton"
import { Tenants } from "../tenants/types"

/**
 * the valuation session id is generated each time the user starts a new
 * valuation flow => lands on the /1/1 page
 */
const VALUATION_ID_SESSION_KEY = "seller_valuation_id"
export const generateAndStoreValuationSessionId = () => {
  const sellerValuationId = uuidv4()
  sessionStorage.setItem(VALUATION_ID_SESSION_KEY, sellerValuationId)
  return sellerValuationId
}
export const getValuationSessionId = () => {
  try {
    return sessionStorage.getItem(VALUATION_ID_SESSION_KEY)
  } catch (ex) {
    getLoggerServiceSingleton().error(new Error("Session storage not accessible"))
    return null
  }
}

export const getCity = (address: Address, tenant: Tenants) => {
  if (!address.city) {
    return "not set"
  }

  if (address.zipCode && validateZipCode({ country: address.countryCode, zipCode: address.zipCode, tenant })) {
    return address.city
  }

  return "other"
}

export const getGTMPage = (): string => document.location.pathname + document.location.search

export const getGTMLocation = (): string =>
  document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search

export const getGoogleClientId = (): string => {
  try {
    return window.ga && typeof window.ga.getAll === "function" ? window.ga.getAll()[0].get("clientId") : ""
  } catch (err) {
    return ""
  }
}

export interface IPushToDataLayer {
  (arg1: number, arg2: string, data: any): void
}
export const pushFlowStepToDataLayer: IPushToDataLayer = (step: number, action: string, additionalData: any) => {
  if (isDevOrStaging()) {
    console.info("[EVENT/GTM]", { step, action, additionalData })
  }

  if (!window.dataLayer) return

  window.dataLayer.push({
    event: "casavo.valuation_flow",
    flow: {
      action,
      step: `0${step}`,
    },
    originalLocation: getGTMLocation(),
    page: getGTMPage(),
    valuationSessionId: getValuationSessionId(),
    ...additionalData,
  })
}

export interface ITrackUserInteraction {
  (category: string, action: string, label: string, data?: Record<string, string | number | boolean>): void
}
export const trackUserInteraction: ITrackUserInteraction = (
  category: string,
  action: string,
  label: string,
  data?: Record<string, string | number | boolean>
) => {
  if (isDevOrStaging()) {
    console.info("[EVENT/GTM]", `${category}_${label}_${action}`)
  }

  if (!window.dataLayer) return

  window.dataLayer.push({
    event: "casavo.user.interactions",
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    valuationSessionId: getValuationSessionId(),
    ...data,
  })
}

const trackAutocompleteLoadingTime = () => {
  let canTrackAutocompleteLoadingTime: boolean | undefined = undefined

  return {
    setTrackingEnabled: () => {
      if (canTrackAutocompleteLoadingTime === undefined) {
        canTrackAutocompleteLoadingTime = true
      }
    },
    setTrackingDone: () => {
      canTrackAutocompleteLoadingTime = false
    },
    shouldTrack: (): boolean => {
      return !!canTrackAutocompleteLoadingTime
    },
  }
}

export const autocompleteLoadingTime = trackAutocompleteLoadingTime()
