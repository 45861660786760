import { isDevOrStaging } from "../envUtils"
import { CompositeLogger } from "../services/Logger"
import { ConsoleLogger } from "../services/Logger/ConsoleLogger"
import { ILogger } from "../services/Logger/types"

const notFalse = <T>(param: T | false): param is T => param !== false

let loggerService: ILogger | null = null

export const getLoggerServiceSingleton = (): ILogger => {
  if (loggerService === null) {
    loggerService = new CompositeLogger(
      [
        isDevOrStaging() && new ConsoleLogger(), // we don't need console.logs in production
      ].filter(notFalse)
    )
  }
  return loggerService
}
