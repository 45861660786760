import { Address } from "@app/types/address"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export type AddressState = Address

const initialState: AddressState = {
  city: "",
  coordinates: {
    latitude: null,
    longitude: null,
  },
  country: "",
  countryCode: "",
  street: "",
  number: "",
  zipCode: "",
}

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddress: (_state, action: PayloadAction<Address>) => {
      return action.payload
    },
  },
})

export const { setAddress } = addressSlice.actions

export const addressReducer = addressSlice.reducer

export const addressSelector = (state: RootState) => state.address
