import { useCallback } from "react"

import { useAppDispatch, useAppSelector } from "@app/store"
import { addressSelector, setAddress } from "@app/store/address/addressSlice"
import { Address } from "@app/types/address"

export const useAddress: () => [Address, (x: Address | ((pref: Address) => Address)) => void] = () => {
  const state = useAppSelector(addressSelector)
  const dispatch = useAppDispatch()

  const setState = useCallback(
    (newState: Address | ((pref: Address) => Address)) => {
      if (typeof newState === "function") {
        dispatch(setAddress(newState(state)))
      } else {
        dispatch(setAddress(newState))
      }
    },
    [dispatch, state]
  )
  return [state, setState]
}
