import { SnakeService } from "@app/lib/services/SnakeService/SnakeService"
import axios from "axios"
import { IDependenciesContainer } from "../ioc/DependenciesContainer"
import { FlowDependencies } from "../ioc/flowDependencies"
import { getLoggerServiceSingleton } from "../ioc/loggerServiceSingleton"
import { pushFlowStepToDataLayer, trackUserInteraction } from "../tracking"
import { buildPotentialBuyerMatchingService } from "./buildPotentialBuyerMatchingService"

export function initContainer(container: IDependenciesContainer<FlowDependencies>) {
  container.set("pushToDataLayerTracker", pushFlowStepToDataLayer)
  container.set("userInteractionTracker", trackUserInteraction)
  container.set("logger", getLoggerServiceSingleton())
  const snakeService = new SnakeService(axios)
  container.set("snakeService", snakeService)
  container.set("propertyValuationService", snakeService)
  container.set("potentialBuyerService", buildPotentialBuyerMatchingService(axios))
}
