import { isRunningE2ETests, isStorybook } from "../envUtils"
import { TENANTS, TENANT_DOMAIN_ENVIRONMENT_MAPPING } from "./constants"
import { Tenants } from "./types"

const isDevEnvironment = () => isStorybook() || isRunningE2ETests()

export const getTenant = (host?: string | null): Tenants => {
  if (typeof window !== "undefined" && isDevEnvironment()) {
    return (window as any).testForTenant ?? TENANTS.casavo
  }
  if (!host && typeof window !== "undefined") {
    host = window.location.host
  }

  if (!host && typeof window === "undefined") {
    throw new Error("Host unavailable on the server")
  }

  if (host === null || host === undefined) {
    throw new Error("Cannot get tenant with nullish host")
  }

  const [domain, _port] = host.split(":")
  const firstSignificantSubdomain =
    domain.split(".").find((subdomain) => Boolean(subdomain.match(/^\d+$/)?.length) === false) ?? null

  switch (firstSignificantSubdomain) {
    case TENANT_DOMAIN_ENVIRONMENT_MAPPING.unicreditStaging:
    case TENANT_DOMAIN_ENVIRONMENT_MAPPING.unicreditProduction:
    case TENANT_DOMAIN_ENVIRONMENT_MAPPING.unicreditPreview:
      return TENANTS.ucsc
    default:
      return TENANTS.casavo
  }
}

const getTenantWithFallback =
  (fallbackTenant: Tenants) =>
  (host?: string | null): Tenants => {
    try {
      return getTenant(host)
    } catch (ex) {
      return fallbackTenant
    }
  }

export const getTenantWithCasavoFallback = getTenantWithFallback(TENANTS.casavo)
export const getTenantWithUnicreditFallback = getTenantWithFallback(TENANTS.ucsc)
