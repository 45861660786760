import { ucscTheme } from "@app/design-lib/style-theme/tenant/ucscTheme"
import { TENANTS } from "@app/lib/tenants/constants"
import { getTenantWithCasavoFallback } from "@app/lib/tenants/getTenant"
import { Tenants } from "@app/lib/tenants/types"
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import React, { ReactNode } from "react"
import { theme as defaultTheme, ThemeConfig } from "../theme"

export const getTheme = (tenant?: Tenants) => {
  switch (tenant ?? getTenantWithCasavoFallback()) {
    case TENANTS.ucsc:
      return ucscTheme
    case TENANTS.casavo:
    default:
      return defaultTheme
  }
}

export const ThemeProvider: React.FC<{ children: ReactNode }> = (props) => {
  const theme: ThemeConfig = getTheme()
  return <EmotionThemeProvider theme={theme}>{props.children}</EmotionThemeProvider>
}
