type TealiumBaseEvent = {
  page_path: string
  traffic_source: string | null
  cookie_policy_accepted: string | null
}

type TealiumPageViewEvent = TealiumBaseEvent & {
  event_name: "pageview"
}

type TealiumGeneratedLead = TealiumBaseEvent & {
  event_name: "conferma_richiesta"
  lead_id: string
  lead_type: TealiumGenerateLeadType
  user_email: string
}

export type TealiumGenerateLeadType = "vendo" | "vendo:valutazione" | "residenziale:casavo" | "agente" | "generica"

function tealiumTrack(event: TealiumGeneratedLead | TealiumPageViewEvent) {
  //@ts-ignores
  window.tealiumCoda || (window.tealiumCoda = [])
  //@ts-ignores
  "function" == typeof window.tealiumCoda.track ? window.tealiumCoda.track(event) : window.tealiumCoda.push(event)
}

export function tealiumRecordPageView(
  pagePath: string,
  trafficSource: string | null,
  cookiePolicyAccepted: string | null
) {
  if (trafficSource?.toLowerCase() === "website" && pagePath.includes("1/1")) return

  const event: TealiumPageViewEvent = {
    cookie_policy_accepted: cookiePolicyAccepted,
    traffic_source: trafficSource,
    page_path: pagePath,
    event_name: "pageview",
  }
  tealiumTrack(event)
}

export function tealiumRecordSellingLeadCreation({
  currentPath,
  transactionId,
  sellerEmail,
  trafficSource,
  cookiePolicyAccepted,
  leadType,
}: {
  currentPath: string
  transactionId: string
  sellerEmail: string
  trafficSource: string | null
  cookiePolicyAccepted: string | null
  leadType: TealiumGenerateLeadType
}) {
  const event: TealiumGeneratedLead = {
    cookie_policy_accepted: cookiePolicyAccepted,
    traffic_source: trafficSource,
    page_path: currentPath,
    event_name: "conferma_richiesta",
    user_email: sellerEmail,
    lead_type: leadType,
    lead_id: transactionId,
  }
  tealiumTrack(event)
}
