/* eslint-disable no-restricted-syntax -- we can use fontSize in here */
import styled from "@emotion/styled"
import { ComponentProps, ElementType } from "react"
import { ComponentMap, TokenSupport } from "."
import { ColorKey } from "../colors"

export const desktop = "@media(min-width: 768px)"

type ColorProps = { color?: ColorKey }

const BaseTypography = styled.p<ColorProps>(({ theme, color }) => ({
  color: color && theme.colors[color],
}))

type ParagraphProps = Omit<ComponentProps<"p">, "color">

function buildBaseWithTokenSupport(
  componentName: ElementType<any> = "p"
): React.FC<{ components?: ComponentMap; as?: ElementType<any> } & ParagraphProps> {
  return function heading({ children, components, ...props }) {
    return (
      <BaseTypography as={componentName} {...props}>
        <TokenSupport components={components}>{children}</TokenSupport>
      </BaseTypography>
    )
  }
}

/**
 * fontSize: 32/40,
 *
 * lineHeight: 120%
 */
export const Heading1 = styled(buildBaseWithTokenSupport("h1"))<ColorProps>(() => ({
  fontFamily: "Bagoss",
  fontSize: 32,
  fontWeight: 300,
  lineHeight: "120%",
  [desktop]: {
    fontSize: 40,
  },
}))

/**
 * fontSize: 28/32,
 *
 * lineHeight: 120%
 */
export const Heading2 = styled(buildBaseWithTokenSupport("h2"))<ColorProps>(() => ({
  fontFamily: "Bagoss",
  fontSize: 28,
  fontWeight: 300,
  lineHeight: "120%",
  [desktop]: {
    fontSize: 32,
  },
}))

/**
 * fontSize: 24/28
 *
 * lineHeight: 120%
 */
export const Heading3 = styled(buildBaseWithTokenSupport("h3"))<ColorProps>(() => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: "120%",
  [desktop]: {
    fontSize: 28,
  },
}))

/**
 * fontSize: 20/24
 *
 * lineHeight: 120%
 */
export const Heading4 = styled(buildBaseWithTokenSupport("h4"))<ColorProps>(() => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "120%",
  [desktop]: {
    fontSize: 24,
  },
}))

/**
 * fontSize: 16/20
 *
 * lineHeight: 120%
 */
export const Heading5 = styled(buildBaseWithTokenSupport("h4"))<ColorProps>(() => ({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "120%",
  [desktop]: {
    fontSize: 20,
  },
}))

/**
 * fontSize: 18/20
 *
 * lineHeight: 150%
 */
export const BodyLarge = styled(buildBaseWithTokenSupport())<{ strong?: boolean } & ColorProps>(({ strong }) => ({
  fontSize: 18,
  fontWeight: strong ? 600 : 400,
  lineHeight: "150%",
  [desktop]: {
    fontSize: 20,
  },
}))

/**
 * fontSize: 16/16,
 *
 * lineHeight: 150%
 */
export const BodyMedium = styled(buildBaseWithTokenSupport())<{ strong?: boolean } & ColorProps>(({ strong }) => ({
  fontSize: 16,
  fontWeight: strong ? 600 : 400,
  lineHeight: "150%",
}))

/**
 * fontSize: 14,
 *
 * lineHeight: 150%
 */
export const Description = styled(buildBaseWithTokenSupport())<{ strong?: boolean } & ColorProps>(({ strong }) => ({
  fontSize: 14,
  fontWeight: strong ? 600 : 400,
  lineHeight: "150%",
}))

/**
 * fontSize: 12,
 *
 * lineHeight: 150%
 */
export const Caption = styled(buildBaseWithTokenSupport())<{ strong?: boolean } & ColorProps>(({ strong }) => ({
  fontSize: 12,
  lineHeight: "150%",
  fontWeight: strong ? 600 : 400,
}))

/**
 * fontSize: 11,
 *
 * lineHeight: 150%
 */
export const Small = styled(buildBaseWithTokenSupport())<{ strong?: boolean } & ColorProps>(({ strong }) => ({
  fontSize: 11,
  lineHeight: "150%",
  fontWeight: strong ? 600 : 400,
}))
