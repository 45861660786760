const legacyColors = {
  /** @deprecated */
  primary800: "#B50061",
  /** @deprecated */
  primary700: "#cb006d",
  /** @deprecated */
  primary600: "#e20079",
  /** @deprecated */
  primary500: "#e83394",
  /** @deprecated */
  primary400: "#EE66AF",
  /** @deprecated */
  primary300: "#f399c9",
  /** @deprecated */
  primary200: "#F9CCE4",
  /** @deprecated */
  primary100: "#fce6f2",
  /** @deprecated */
  secondary800: "#19689A",
  /** @deprecated */
  secondary700: "#1c75ad",
  /** @deprecated */
  secondary600: "#1f82c0",
  /** @deprecated */
  secondary500: "#4c9bcd",
  /** @deprecated */
  secondary400: "#79B4D9",
  /** @deprecated */
  secondary300: "#a5cde6",
  /** @deprecated */
  secondary200: "#D2E6F2",
  /** @deprecated */
  secondary100: "#e9f3f9",

  /** @deprecated */
  lightblue800: "#3688AF",
  /** @deprecated */
  lightblue700: "#3d99c5",
  /** @deprecated */
  lightblue600: "#44aadb",
  /** @deprecated */
  lightblue500: "#69bbe2",
  /** @deprecated */
  lightblue400: "#69BBE2",
  /** @deprecated */
  lightblue300: "#b4ddf1",
  /** @deprecated */
  lightblue200: "#DAEEF8",
  /** @deprecated */
  lightblue100: "#ecf7fb",

  /** @deprecated */
  cyan800: "#529CB2",
  /** @deprecated */
  cyan700: "#5cb0c9",
  /** @deprecated */
  cyan600: "#66c3df",
  /** @deprecated */
  cyan500: "#85cfe5",
  /** @deprecated */
  cyan400: "#A3DBEC",
  /** @deprecated */
  cyan300: "#c2e7f2",
  /** @deprecated */
  cyan200: "#E0F3F9",
  /** @deprecated */
  cyan100: "#f0f9fc",

  /** @deprecated */
  plum800: "#8A3672",
  /** @deprecated */
  plum700: "#9c3d80",
  /** @deprecated */
  plum600: "#ad448e",
  /** @deprecated */
  plum500: "#bd69a5",
  /** @deprecated */
  plum400: "#CE8FBB",
  /** @deprecated */
  plum300: "#deb4d2",
  /** @deprecated */
  plum200: "#EFDAE8",
  /** @deprecated */
  plum100: "#f7ecf4",

  /** @deprecated */
  purple800: "#665B8A",
  /** @deprecated */
  purple700: "#72679c",
  /** @deprecated */
  purple600: "#7f72ad",
  /** @deprecated */
  purple500: "#998ebd",
  /** @deprecated */
  purple400: "#B2AACE",
  /** @deprecated */
  purple300: "#ccc7de",
  /** @deprecated */
  purple200: "#E5E3EF",
  /** @deprecated */
  purple100: "#f2f1f7",

  /** @deprecated */
  white100: "rgba(255,255,255,1)",
  /** @deprecated */
  white80: "rgba(255,255,255,0.8)",
  /** @deprecated */
  white50: "rgba(255,255,255,0.5)",
}

export const colors = {
  ...legacyColors,

  orange700: "#E68A1C", //orange700
  orange600: "#FF991F", //orange600
  orange500: "#FFB862", //orange500
  orange400: "#FFD6A5", // orange400
  orange100: "#FFF5E9", //orange100

  deepGreen700: "#05312E",
  deepGreen600: "#063D39",
  deepGreen500: "#517774",
  deepGreen400: "#9BB1B0", //deepGreen400
  deepGreen100: "#E6ECEB",

  green700: "#1E9A82",
  green600: "#25C1A3",
  green500: "#66D4BF",
  green400: "#A8E6DA",
  green100: "#E9F9F6",

  lightBlue: "#D6F5FF",

  offWhite: "#F6F6F1", // off-white

  grey600: "#1D1D1B", // gray600
  grey500: "#525251",
  grey400: "#737372",
  grey300: "#B3B3B2",
  grey200: "#D4D4D3",
  grey100: "#E8E8E8",
  grey50: "#F5F5F5",
  grey0: "#FFFFFF",

  success800: "#529A46",
  success700: "#5DAD4E",
  success600: "#67C057",
  success500: "#85CD79",
  success400: "#A4D99A",
  success100: "#F0F9EE",

  warning800: "#CC9A36",
  warning700: "#E6AD3C",
  warning600: "#FFC043",
  warning500: "#FFCD69",
  warning400: "#FFD98E", //warning400
  warning100: "#FFF9EC",

  error800: "#BC4646",
  error700: "#D44E4E",
  error600: "#EB5757",
  error500: "#EF7979",
  error400: "#F39A9A",
  error100: "#FDEEEE",

  // branded colors may be overridden by the theme (ThemeProvider)
  brandedButtonBackground: "#1D1D1B", // gray600
  brandedButtonBorder: "#1D1D1B", // gray600
  brandedAvatarBorder: "#FF991F", //orange600
  brandedRadioOptionBorder: "#E68A1C", //orange700
  brandedRadioOptionBackground: "#FFF5E9", //orange100
  brandedTextLink: "#1D1D1B", // gray600
  brandedInputFocus: "#E68A1C", //orange700
  brandedCheckboxSelected: "#E68A1C", //orange700
  brandedOptionBackgroundSelected: "#FFF5E9", //orange100
  brandedOptionBorderSelected: "#E68A1C", //orange700
  brandedLoaderColor1: "#FFD98E", //warning400
  brandedLoaderColor2: "#E68A1C", //orange700
  brandedSubmissionBannerBackground: "#FFB862", //orange500
  brandedFileBoxBackground: "#F6F6F1", // off-white
  brandedSubmissionBannerCircleBackground: "#1D1D1B", // gray600
  brandedTextInputFocus: "#E68A1C", //orange700
  brandedFollowUpBackground: "#E7F8FB",
  brandedIconColor: "#E68A1C", //orange700
  brandedIconColorTenantOnly: "#1D1D1B", // gray600
  brandedIconBackground: "#FFD98E", //warning400
  brandedChevron: "#FF991F", //orange600
  brandedChipSoldBackground: "#9BB1B0", //deepGreen400
  brandedChipBackground: "#FFD6A5", // orange400
  brandedCardHeaderBackground: "#F6F6F1", // off-white

  // Badge
  brandedBadgeBackground: "#FFD6A5", // orange400
  brandedBadgeBorderColor: "#FFF5E9", //orange100

  // LightBadge
  brandedLightBadgeBackground: "#FF991F", //orange600
  brandedLightBadgeBorderColor: "#FF991F", //orange600
  brandedLightBadgeTextColor: "#1D1D1B", // gray600

  brandedCardBackground: "#F6F6F1", // off-white
  brandedCardBorderSelected: "#FFD6A5", // orange400
  brandedComparableCardDotActive: "#FF991F", //orange600
  brandedComparablePriceWrapperBackground: "#F6F6F1", // off-white

  brandedPotentialBuyerAvatarBorder: "#1D1D1B", // grey600
  brandedInterestedBuyerAvatarBorder: "#5DAD4E", // success700
  brandedInterestedBuyersBackground: "#A4D99A", // success400
  brandedInterestedBuyerAdditionalBackground: "#063D39", // deepGreen600
  brandedPotentialBuyerCardBorder: "#FF991F", //orange600
  brandedShareWithBuyerCardBackground: "#FFF5E9", //orange100
  brandedDataGatheringCompletedCardBackground: "#F0F9EE", //success100
  brandedSaleProposalCardBackground: "#FFB862", //orange500

  brandedAlertInfoBackground: "#E6ECEB", //deepGreen100
}

export type ColorKey = keyof typeof colors
