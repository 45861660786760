import { ILogger, LogMessage } from "./types"

export class CompositeLogger implements ILogger {
  private loggers: ILogger[]

  constructor(loggers: ILogger[]) {
    this.loggers = loggers
  }

  error(error: LogMessage | Error) {
    this.loggers.forEach((logger) => logger.error(error))
  }

  warning(message: LogMessage) {
    this.loggers.forEach((logger) => logger.warning(message))
  }

  info(message: LogMessage) {
    this.loggers.forEach((logger) => logger.info(message))
  }

  debug(message: LogMessage) {
    this.loggers.forEach((logger) => logger.debug(message))
  }
}
