import { Answers } from "@app/types/survey"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export type AnswersState = Answers

const initialState: AnswersState = {}

export const answersSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    setAnswers: (_state, action: PayloadAction<Answers>) => {
      return action.payload
    },
  },
})

export const { setAnswers } = answersSlice.actions

export const answersReducer = answersSlice.reducer

export const answersSelector = (state: RootState) => state.answers
