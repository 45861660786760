import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { AppSelector } from "../store"

export type DataGatheringState = {
  hasSkippedFloorPlan: boolean
}

const initialState: DataGatheringState = {
  hasSkippedFloorPlan: false,
}

export const dataGatheringSlice = createSlice({
  name: "dataGathering",
  initialState,
  reducers: {
    dataGatheringSetSkippedFloorPlanFlag: (state, action: PayloadAction<boolean>) => {
      state.hasSkippedFloorPlan = action.payload
    },
  },
})

export const { dataGatheringSetSkippedFloorPlanFlag } = dataGatheringSlice.actions

export const dataGatheringReducer = dataGatheringSlice.reducer

export const hasSkippedFloorPlanSelector: AppSelector<boolean> = (state) => state.dataGathering.hasSkippedFloorPlan
