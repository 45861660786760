import { FlowDependencies } from "./flowDependencies"

type Dependencies = {}
export interface IDependenciesContainer<TDeps extends Dependencies = FlowDependencies> {
  get<TK extends keyof TDeps>(key: TK): TDeps[TK]
  set<TK extends keyof TDeps>(key: TK, value: TDeps[TK]): null
}

export class DependencyNotRegisteredError extends Error {
  constructor(key: symbol | string | number) {
    super(`Dependency not registred: ${typeof key == "string" ? key : key.toString()}`)
  }
}

export class DependenciesContainer<TDeps extends Dependencies = FlowDependencies>
  implements IDependenciesContainer<TDeps>
{
  private container = {} as TDeps

  //                      v this would need to be changed
  constructor(container?: Map<symbol, unknown>) {
    if (container) {
      this.container = Object.fromEntries(container.entries()) as unknown as TDeps
    }
  }

  get<TK extends keyof TDeps>(key: TK): TDeps[TK] {
    const dependency = this.container[key]

    if (!dependency) throw new DependencyNotRegisteredError(key)

    return dependency
  }

  set<TK extends keyof TDeps>(key: TK, value: TDeps[TK]): null {
    this.container[key] = value
    return null
  }
}
