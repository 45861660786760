const regexp = /\/?(?<language>it|es)*\/(?<slug>\[*[A-Za-z-]+)\]?\/(?<step>[0-9]+)\/(?<substep>[0-9]+)/

type Values = {
  language?: string
  slug?: string
  step?: number
  substep?: number
}

export function pathToRegExp(path: string) {
  return regexp.exec(path)
}

function valueOrGroup(groupName: keyof Values, values: Values) {
  return values[groupName] ? values[groupName] : `$<${groupName}>`
}

export function compilePath(path: string, values: Values) {
  const parsedPath = pathToRegExp(path)

  return path.replace(
    regexp,
    `${parsedPath?.groups?.language ? "/" : ""}${[
      valueOrGroup("language", values),
      valueOrGroup("slug", values),
      valueOrGroup("step", values),
      valueOrGroup("substep", values),
    ].join("/")}`
  )
}
