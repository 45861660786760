declare const window: Window &
  typeof globalThis & {
    grecaptcha: any | undefined
  }
export function getRecaptchaRequestToken(action: string): Promise<string | undefined> {
  const key = process.env.NEXT_PUBLIC_RECAPTCHA_KEY
  //https://cloud.google.com/recaptcha-enterprise/docs/api-ref-checkbox-keys
  const recatchaApi = window.grecaptcha?.enterprise
  if (recatchaApi === undefined) {
    return Promise.resolve(undefined)
  }
  return new Promise((resolve, reject) => {
    recatchaApi.ready(() => {
      recatchaApi.execute(key, { action: action }).then(resolve).catch(reject)
    })
  })
}
