import { dataGatheringReducer } from "@app/store/datagathering/dataGatheringSlice"
import { combineReducers, configureStore, Selector } from "@reduxjs/toolkit"
import produce from "immer"
import type { TypedUseSelectorHook } from "react-redux"
import { useDispatch, useSelector } from "react-redux"
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import storageSession from "redux-persist/lib/storage/session"
import { addressReducer } from "./address/addressSlice"
import { answersReducer } from "./answers/answersSlice"
import { contactReducer } from "./contact/contactSlice"
import { resetStore } from "./global"
import { reportReducer } from "./report/reportSlice"
import { valuationResultReducer } from "./valuation/valuationSlice"

const appReducer = combineReducers({
  valuationResult: valuationResultReducer,
  address: addressReducer,
  answers: answersReducer,
  report: reportReducer,
  contact: contactReducer,
  dataGathering: dataGatheringReducer,
})

const persistConfig = {
  key: "root",
  storage: storageSession,
}

export const rootReducer = (state: Parameters<typeof appReducer>[0], action: Parameters<typeof appReducer>[1]) => {
  if (action.type === resetStore.type) {
    return appReducer(undefined, { type: undefined })
  }

  if (action.type === "set_state") {
    return action.payload
  }

  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const createStoreWithStateOverride = (modifyState: (state: RootState) => RootState | void) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState: produce(rootInitialState, modifyState),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })
}
export const persistor = persistStore(store)

export const rootInitialState = store.getState()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppSelector<ReturnType> = Selector<RootState, ReturnType>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
