import { Fragment, ReactNode, useEffect } from "react"

import useCurrentStep from "@app/hooks/useCurrentStep"
import usePrevious from "@app/hooks/usePrevious"
import useTracking from "@app/hooks/useTracking"

const getDataLayerAction = (step: number, substep: number): string => {
  if (step === 3 && substep === 2) {
    return "ContactForm"
  } else if (step === 4) {
    return "ValuationLoader"
  } else if (step === 5) {
    return "ValuationPage"
  }
  return `Step${substep}`
}

const Tracker: React.FC<{ children: ReactNode }> = (props) => {
  const { pushToDataLayer } = useTracking()
  const [currentStep, currentSubStep] = useCurrentStep()
  const previousStep = usePrevious(currentStep)
  const previousSubStep = usePrevious(currentSubStep)

  useEffect(() => {
    if (previousStep === undefined || previousSubStep === undefined) return

    // Trigger dataLayer event only on step or substep change
    if (currentStep !== previousStep || currentSubStep !== previousSubStep) {
      pushToDataLayer(getDataLayerAction(currentStep, currentSubStep), {
        move: currentStep > previousStep || currentSubStep > previousSubStep ? "next" : "prev",
      })
    }
  }, [currentStep, currentSubStep, previousStep, previousSubStep, pushToDataLayer])

  return <Fragment>{props.children}</Fragment>
}

export default Tracker
