import { PotentialBuyers } from "@app/types/PotentialBuyers"

export function limitTotalCountTo<T extends PotentialBuyers>(maxTotalCount: number): (potentialBuyers: T) => T {
  return (potentialBuyers: T) => {
    return {
      ...potentialBuyers,
      totalCount: Math.min(potentialBuyers.totalCount, maxTotalCount),
    }
  }
}

export function takeFirstNPotentialBuyers<T extends PotentialBuyers>(
  maxPotentialBuyer: number
): (potentialBuyers: T) => T {
  return (potentialBuyers: T) => {
    return {
      ...potentialBuyers,
      potentialBuyers: potentialBuyers.potentialBuyers.slice(0, maxPotentialBuyer),
    }
  }
}

export function takeAtLeastNPotentialBuyer<T extends PotentialBuyers>(
  minPotentialBuyer: number
): (potentialBuyers: T) => T | undefined {
  return (potentialBuyers: T) => {
    if (
      potentialBuyers.totalCount >= minPotentialBuyer &&
      potentialBuyers.potentialBuyers.length >= minPotentialBuyer
    ) {
      return potentialBuyers
    } else {
      return undefined
    }
  }
}
