import { Global, useTheme } from "@emotion/react"

const GlobalStyles = () => {
  const theme = useTheme()

  return (
    <Global
      styles={{
        body: {
          color: theme.colors.grey600,
        },
      }}
    />
  )
}

export default GlobalStyles
