export const hasGivenMeasurementTrackingConsent = () => {
  try {
    return (window as any)._iub.cs.consent.purposes[4]
  } catch {
    return false
  }
}

export const hasExpressedMeasurementTrackingPreference = () => {
  try {
    return (window as any)._iub.cs.consent.purposes[4] !== undefined
  } catch {
    return false
  }
}

export const hasGivenMarketingTrackingPreference = () => {
  try {
    return (window as any)._iub.cs.consent.purposes[5] !== undefined
  } catch {
    return false
  }
}
